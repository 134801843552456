.sponsorlist-wrapper {
    padding-left: 10%;
    padding-right: 10%;
}

.sponsorlist-grid {
    display: grid;
    padding: 100px;
    gap: 10px;
    grid-template-columns: repeat(3, 1fr);
    align-items: start;
}

.sponsor-cover {
    width: 100%;
    height: 120%;
    object-fit: cover;
    overflow: hidden;
    transform: translateY(-100px);

}

.sponsorlist-item {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center; /* Center logos horizontally */
}

.sponsorlist-item img {
    max-width: 100%;
    max-height: 250px;
    display: block;
}

.sponsorlist-item .sponsor-names {
    display: flex;
    flex-direction: column;
}